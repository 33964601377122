<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Asset Finance Products</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Loans</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="loan-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="loan-image">
                    <img src="assets/img/loan.png" alt="image">
                    <!-- <div class="loan-shape">
                        <div class="text">
                            <img src="assets/img/logo.png" alt="image">
                            <span>We believe in those made to do more</span>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="loan-content">
                    <h3>What is a Secured Car loan?</h3>
                    <p>A Secured Car Loan is a type of car loan that involves the lender holding a security against the car itself. If you are unable to meet your repayments, the lender can repossess your car and sell it to pay off the amount still owed.</p>
                    <p>However, the major benefits over unsecured loans (such as Personal Loan) are better loan terms with higher borrowing limits and more importantly, lower interest rates.</p>

                    <ul class="list">
                        <li>Variable rates from 6.9%*</li>
                        <li>Fixed rates from 6.39%*</li>
                        <li>Loan term 3 - 7 years.</li>
                        <!-- <li>No minimum trading requirement.</li> -->
                    </ul>

                    <!-- <h4>Resources to help you with your Loan</h4>

                    <ul class="loan-list">
                        <li><i class="flaticon-check"></i> Business Plan</li>
                        <li><i class="flaticon-check"></i> Budget Planner</li>
                        <li><i class="flaticon-check"></i> Cashflow Forecast</li>
                    </ul> -->

                    <div class="loan-btn">
                        <a routerLink="/apply-now" class="default-btn">Apply Now<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="loan-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="loan-image">
                    <img src="assets/img/loan.png" alt="image">
                    <!-- <div class="loan-shape">
                        <div class="text">
                            <img src="assets/img/logo.png" alt="image">
                            <span>We believe in those made to do more</span>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="loan-content">
                    <h3>What is an Unsecured Car loan?</h3>
                    <p>Using a Personal Loan to buy your car, otherwise known as an Unsecured Car Loan, involves a lender providing you the funds to purchase a car of your choice. It is beneficial if you are looking to purchase an older vehicle, grey import or vintage car.</p>
                    <p>With this type of loan there is no security held on the loan, so the lender considers it a bigger risk which generally means a higher interest rate than a Secured Loan.</p>

                    <ul class="list">
                        <!-- <li>Variable rates from 6.9%*</li> -->
                        <li>Fixed rates from 6.39%*</li>
                        <li>Loan term 2 - 7 years</li>
                        <li>No security held on the loan</li>
                    </ul>

                    <!-- <h4>Resources to help you with your Loan</h4>

                    <ul class="loan-list">
                        <li><i class="flaticon-check"></i> Business Plan</li>
                        <li><i class="flaticon-check"></i> Budget Planner</li>
                        <li><i class="flaticon-check"></i> Cashflow Forecast</li>
                    </ul> -->

                    <div class="loan-btn">
                        <a routerLink="/apply-now" class="default-btn">Apply Now<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="loan-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="loan-image">
                    <img src="assets/img/loan.png" alt="image">
                    <!-- <div class="loan-shape">
                        <div class="text">
                            <img src="assets/img/logo.png" alt="image">
                            <span>We believe in those made to do more</span>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="loan-content">
                    <h3>What Chattel Mortgage?</h3>
                    <p>A Chattel Mortgage is the same product as a Secured Car Loan only for assets that are primarily for business purposes. A tax deduction may be available on the asset when the vehicle is being used for business. In addition, a customer who is GST registered may claim the GST component of the vehicle price as an input credit on their next Business Activity Statement.</p>
                    <!-- <p>However, the major benefits over unsecured loans (such as Personal Loan) are better loan terms with higher borrowing limits and more importantly, lower interest rates.</p> -->

                    <ul class="list">
                        <!-- <li>Variable rates from 6.9%*</li> -->
                        <li>Fixed rates from 6.39%*</li>
                        <li>Loan term 3 - 7 years.</li>
                        <!-- <li>No minimum trading requirement.</li> -->
                    </ul>

                    <!-- <h4>Resources to help you with your Loan</h4>

                    <ul class="loan-list">
                        <li><i class="flaticon-check"></i> Business Plan</li>
                        <li><i class="flaticon-check"></i> Budget Planner</li>
                        <li><i class="flaticon-check"></i> Cashflow Forecast</li>
                    </ul> -->

                    <div class="loan-btn">
                        <a routerLink="/apply-now" class="default-btn">Apply Now<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="overview-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>What’s the process?</span>
            <h2>The Loans have helped us move our business forward</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="overview-item">
                    <div class="number">
                        <span>Apply</span>
                        <strong>1</strong>
                    </div>
                    <h3>Easily apply in <br> minutes</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="overview-item">
                    <div class="number">
                        <span>Process</span>
                        <strong>2</strong>
                    </div>
                    <h3>Clear and transparent <br> process</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="overview-item">
                    <div class="number">
                        <span>Support</span>
                        <strong>3</strong>
                    </div>
                    <h3>Support from real <br> people</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="overview-shape">
        <div class="shape">
            <img src="assets/img/works-shape.png" alt="image">
        </div>
        <div class="shape2">
            <img src="assets/img/works-shape2.png" alt="image">
        </div>
    </div>
</section> -->

<!-- <section class="deserve-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="deserve-item">
                    <h3>How do I apply for a loan</h3>

                    <div class="deserve-content">
                        <span>1</span>
                        <h4>Apply in 10 minutes</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-content">
                        <span>2</span>
                        <h4>Hear from us in 1 hour</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-content">
                        <span>3</span>
                        <h4>A decision in 24 hours</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-content">
                        <span>4</span>
                        <h4>Your loan is funded</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-btn">
                        <a routerLink="/apply-now" class="default-btn">Apply Now<span></span></a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-6">
                <div class="default-image">
                    <img src="assets/img/loan2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="check-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="check-image">
                    <img src="assets/img/check.png" alt="image">
                </div>
            </div>

            <div class="col-lg-5">
                <div class="check-content">
                    <h3>Want to check your eligibility before you apply?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
                <div class="check-text">
                    <h3>Finix payment flexibility for your loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <ul class="list">
                        <li>Fill Loan Application Form</li>
                        <li>Choose your preferred monthly payment</li>
                        <li>Increase your payment amount anytime</li>
                        <li>No fees, hidden or otherwise</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="inquiry-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="inquiry-content">
                    <h3>Start-up  loan Inquiry</h3>
                    <p>Please complete the short form below with your details and one of our advisors will be touch shortly</p>
                </div>

                <div class="inquiry-form">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group mb-3">
                                <label>Your Name*</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mb-3">
                                <label>Phone Number*</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mb-3">
                                <label>Email Address</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mb-3">
                                <label>Postcode*</label>
                                <input type="text" class="form-control">
                            </div>    
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mb-3">
                                <label>Date of Birth</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mb-3">
                                <label>Address</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mb-3">
                                <label>City</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mb-3">
                                <label>How long have you been trading?</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="inquiry-btn">
                        <a routerLink="/apply-now" class="default-btn">Submit<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="rate-form">
                    <div class="rate-content">
                        <span>Calculate your rate</span>
                        <h3>How much do you need?</h3>
                    </div>
                    <div class="form">
                        <div class="form-group mb-3">
                            <label>Type The Money</label>
                            <input type="text" class="form-control" placeholder="$1000">
                        </div>
                        <div class="form-group mb-3">
                            <label>Select The Month/Years</label>
                            <div class="select-box">
                                <select class="form-control">
                                    <option value="5">1 month</option>
                                    <option value="1">2 month</option>
                                    <option value="2">3 month</option>
                                    <option value="0">4 month</option>
                                    <option value="3">6 month</option>
                                    <option value="3">1 Year</option>
                                    <option value="0">2 Years</option>
                                    <option value="3">3 Years</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label>Borrowing</label>
                            <input type="text" class="form-control" placeholder="$10000">
                        </div>
                        <div class="form-group mb-3">
                            <label>Term of use</label>
                            <input type="text" class="form-control" placeholder="6 month">
                        </div>
                        <div class="form-group mb-3">
                            <label>The total you will pay</label>
                            <input type="text" class="form-control" placeholder="$11200">
                        </div>
                        <div class="rate-btn">
                            <button type="submit" class="default-btn">Apply for this loan<span></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->